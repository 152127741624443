import { inject, Injectable } from '@angular/core';
import { FilledContentRelationshipField, LinkField } from '@prismicio/client';
import { ProductCatalogService, RequestParams } from '@yol-digital/ms-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerLookupService } from 'customer-lookup';
import { Promotion } from 'interfaces';
import { PosRedirectService } from 'pos-redirect';
import { Product, ProductService } from 'product-shared';
import { buildUrl } from 'utils';
import CatalogResponse = ProductCatalogService.CatalogResponse;

@Injectable({
  providedIn: 'root',
})
export class PosProductService extends ProductService {
  private posRedirectService = inject(PosRedirectService);
  private customerLookupService = inject(CustomerLookupService);
  private featureFlagMap = {
    HFC: 'hfc-pos-checkout-feature',
    FIBER: 'fiber-pos-checkout-feature',
    MBB: 'mbb-pos-checkout-feature',
    POSTPAID: 'postpaid-pos-checkout-feature',
    TV: 'tv-pos-checkout-feature',
    PREPAID: 'prepaid-pos-checkout-feature',
    WATCH: 'watch-pos-checkout-feature',
  };

  private buildCheckoutUrlOrSlug(
    product: Product,
    queryParamsObj: { [key: string]: string },
    isFeatureFlagOff: boolean
  ): { url?: string; slug?: string } {
    const { product_code, type, url, productSpecClass } = product;
    const newCheckoutSlug = this.posRedirectService.getPosCheckoutUrl(product_code);

    const legacyCheckoutUrl = this.posRedirectService.getLegacyPosCheckoutUrl(type, url, productSpecClass);

    const existingCustomerUsername = this.customerLookupService.customerUsername;

    if (
      isFeatureFlagOff &&
      (productSpecClass === 'FIBER' || productSpecClass === 'HFC' || !!existingCustomerUsername)
    ) {
      return { url: buildUrl(legacyCheckoutUrl) };
    }

    if (isFeatureFlagOff) {
      return { url: buildUrl(legacyCheckoutUrl, queryParamsObj) };
    }

    return { slug: newCheckoutSlug };
  }

  protected getCatalog(query?: { code?: string }, params?: RequestParams) {
    const ziraEnabledProductClasses = this.featureFlagService.getFeatureValue('zira-enabled-product-classes', '');
    return ziraEnabledProductClasses?.length > 0
      ? this.api.pos.getCatalog(
          {
            ...query,
            source: 'zira',
            productSpecClass: ziraEnabledProductClasses,
          },
          params
        )
      : this.api.pos.getCatalog(query, params);
  }

  public isLineCheckHidden() {
    return (
      !this.featureFlagService.isOn(this.featureFlagMap.HFC) && !this.featureFlagService.isOn(this.featureFlagMap.FIBER)
    );
  }

  public getPromotionByCode(code: string) {
    return this.api.pos.getPromotionByCode(code);
  }

  public getPCProducts$(): Observable<CatalogResponse[]> {
    return this.observableDataLoader.get('ProductCatalogPOS', () =>
      this.getCatalog().pipe(map((res: { plans: CatalogResponse[] }) => res.plans))
    );
  }

  public getPCProductByCode(code: string) {
    return this.observableDataLoader.get('POS_PC_product_' + code, () =>
      this.getCatalog({ code }).pipe(
        map((res: { plans: CatalogResponse[] }) => {
          return res.plans.find(product => product.code === code);
        })
      )
    );
  }

  public buildCheckoutPath(
    product: Product,
    promotion?: Promotion,
    lineCheckId?: string
  ): { link: LinkField; queryParams?: object } {
    const featureFlag = this.featureFlagMap[product.productSpecClass] ?? '';
    const isFeatureFlagOff = featureFlag && !this.featureFlagService.isOn(featureFlag);
    const queryParamsObj = this.buildCheckoutQueryParams(product, promotion, lineCheckId, isFeatureFlagOff);
    const { url, slug } = this.buildCheckoutUrlOrSlug(product, queryParamsObj, isFeatureFlagOff);

    return {
      link: <LinkField>{
        ...(url ? { url } : {}),
        ...(slug ? { slug } : {}),
        link_type: slug ? 'Document' : 'Web',
      },
      ...(slug ? { queryParams: queryParamsObj } : {}),
    };
  }

  public productDetailsLink(product: Product) {
    const { product_details_link, url } = product;

    // Early return if product_details_link is undefined or has link_type 'Any'
    if (!product_details_link || product_details_link.link_type === 'Any') {
      return this.buildLink(this.posRedirectService.getPosProductDetailsUrl(`mobile-products/${url}`));
    }

    const { slug, url: absoluteUrl } = product_details_link as FilledContentRelationshipField;

    return this.buildLink(absoluteUrl ?? this.posRedirectService.getPosProductDetailsUrl(slug));
  }

  private buildLink(url: string) {
    return <LinkField>{
      link_type: 'Web',
      url,
      target: '_blank',
    };
  }

  public customProductsFromPrismic(): Observable<{ productCode: string; promotionCode: string }[]> {
    throw new Error('Method not implemented.');
  }
}
